import React, { useState } from "react";
import Fade from "react-reveal/Fade";

import Insights from "../../molecules/Insights";
import { useLanguage } from "../../languageContext";

const IndustryCasestudyTabs = ({
  insights,
  linkedinArticlesUrl,
  data,
  heading,
}) => {
  const currentLanguage = useLanguage();
  const [caseStudies, setCaseStudies] = useState(true);
  const [newsRooms, setNewsRooms] = useState(false);
  const [linkedinArticles, setLinkedinArticles] = useState(false);
  const [blogs, setBlogs] = useState(false);

  const showCaseStudies = () => {
    setCaseStudies(true);
    setNewsRooms(false);
    setLinkedinArticles(false);
    setBlogs(false);
  };

  const showNewsRooms = () => {
    setCaseStudies(false);
    setNewsRooms(true);
    setLinkedinArticles(false);
    setBlogs(false);
  };

  const showLinkedinArticles = () => {
    setCaseStudies(false);
    setNewsRooms(false);
    setLinkedinArticles(true);
    setBlogs(false);
  };

  const showBlogs = () => {
    setCaseStudies(false);
    setNewsRooms(false);
    setLinkedinArticles(false);
    setBlogs(true);
  };

  return (
    // <Fade duration={100}>
    <div className="section casestudy-section m-top-10">
      <div className="container">
        <Fade duaration={1000}>
          <div className="industry-case-study-wrapper">
            <div className="heading heading-bottom-space">{heading}</div>

            <div className="tabs-wrapper common-bottom-space">
              <div
                role="button"
                tabIndex={0}
                onClick={showCaseStudies}
                className={
                  caseStudies
                    ? `tab para active border-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "tab para"
                }
              >
                Case Studies
              </div>

              <div
                role="button"
                tabIndex={0}
                onClick={showNewsRooms}
                className={
                  newsRooms
                    ? `tab para active border-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "tab para"
                }
              >
                News Room
              </div>

              <div
                role="button"
                tabIndex={0}
                onClick={showLinkedinArticles}
                className={
                  linkedinArticles
                    ? `tab para active border-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "tab para"
                }
              >
                Articles
              </div>
            </div>
            <div className="insights-tab-wrapper">
              {caseStudies && (
                <Insights
                  industryInsights={true}
                  insights={insights.filter(
                    (insight) => insight.typeOfInsight === "CASE_STUDIES"
                  )}
                  showTitle={false}
                ></Insights>
              )}
              {newsRooms && (
                <Insights
                  industryInsights={true}
                  insights={insights.filter(
                    (insight) => insight.typeOfInsight === "NEWS_ROOMS"
                  )}
                  showTitle={false}
                ></Insights>
              )}
              {linkedinArticles && (
                <Insights
                  industryInsights={true}
                  class="blog-width"
                  insights={insights.filter(
                    (insight) => insight.typeOfInsight === "BLOG"
                  )}
                  showTitle={false}
                ></Insights>

                // linkedinArticlesUrl.length >0 ?
                // <div className="linkedin-articles">
                //   {linkedinArticlesUrl.map((url) => (
                //     <iframe
                //       src={url}
                //       height="500"
                //       width="400"
                //       frameborder="0"
                //       allowfullscreen=""
                //       title="Embedded post"
                //     ></iframe>
                // ))}
                // </div>:<p  className="insights-container" style={{width:"100%",marginTop:"36px"}}>Thanks for checking. We are building this page bit by bit. Visit again sometime later !</p>
              )}
              {blogs && (
                <Insights
                  industryInsights={true}
                  insights={insights.filter(
                    (insight) => insight.typeOfInsight === "BLOG"
                  )}
                  showTitle={false}
                ></Insights>
              )}
            </div>
          </div>
        </Fade>
      </div>
    </div>
    // </Fade>
  );
};

export default IndustryCasestudyTabs;
