import React from "react";
import icon from "../../../images/company/sinteredLogo.jpg";

const sinteredLogo = () => {
  return (
    <div>
      <img src={icon} alt="speciality sintered icon"></img>
    </div>
  );
};

export default sinteredLogo;
