import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import {
//   CarouselProvider,
//   Slider,
//   Slide,
//   ButtonBack,
//   ButtonNext,
// } from "pure-react-carousel";
// import "pure-react-carousel/dist/react-carousel.es.css";

import leftArren from "../../images/servicesassets/leftArrow-en.png";
import leftArrin from "../../images/servicesassets/leftArrow-in.png";
import leftArrja from "../../images/servicesassets/leftArrow-ja.png";

import rightArren from "../../images/servicesassets/rightArrow-en.png";
import rightArrin from "../../images/servicesassets/rightArrow-in.png";
import rightArrja from "../../images/servicesassets/rightArrow-ja.png";

import RMArrowBlack from "../../images/arrowBlack.png";
import RMArrowBlackEn from "../../images/arrowBlackEn.png";
// import RMArrowIn from "../../images/arrowIn.png";
import RMArrowBlackJa from "../../images/arrowBlackJa.png";
import RMArrowBlackIn from "../../images/arrowBlackIn.png";

import { useLanguage } from "../../languageContext";
import { Rotate, Zoom } from "react-reveal";
import CustomButtons from "../homepage/homepageAboutSlider/CustomButtons";

const Insights = ({
  showBanner,
  insights,
  showTitle,
  marginFromTop,
  homepage,
  bannerUrl,
  industryInsights,
  thumbnails = false,
}) => {
  const currentLanguage = useLanguage();
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 613 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 612, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const data = useStaticQuery(graphql`
    query GetInsightsData {
      gcms {
        homePageInsights {
          insightsBannerHeading
          insightsBannerParagraph
          bannerImage {
            url
          }
        }
      }
    }
  `);

  const domain = "";
  try {
    return (
      <>
        {insights && (
          <div className={`section`}>
            <div
              className={`container section-br ${
                industryInsights ? "" : "p-top-3em "
              } p-bottom-3em  insights-main-container`}
            >
              <div className={`insights-wrapper`}>
                {showTitle && <h2 className="heading">INSIGHTS</h2>}
                <div
                  className={`insights-container ${
                    insights.length > 3
                      ? "slider-btns-container"
                      : "no-btns-container"
                  } four-cards-block `}
                >
                  <Carousel
                    responsive={responsive}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    autoPlaySpeed={5000}
                    autoPlay={false}
                    customButtonGroup={
                      <CustomButtons
                        show={insights.length > 3}
                        leftArr={
                          currentLanguage === "en"
                            ? leftArren
                            : currentLanguage === "in"
                            ? leftArrin
                            : currentLanguage === "ja"
                            ? leftArrja
                            : leftArren
                        }
                        rightArr={
                          currentLanguage === "en"
                            ? rightArren
                            : currentLanguage === "in"
                            ? rightArrin
                            : currentLanguage === "ja"
                            ? rightArrja
                            : rightArren
                        }
                      />
                    }
                  >
                    {insights.length > 0 &&
                      insights.map((cards, index) => {
                        return (
                          <div className="four-cards insight-border">
                            <div className="image-container">
                              <img
                                src={
                                  !thumbnails
                                    ? cards.image?.url
                                      ? cards.image.url
                                      : "404"
                                    : cards.thumbnail.url
                                    ? cards.thumbnail.url
                                    : "404"
                                }
                                alt={cards.title}
                              />
                              <div className="content">
                                <h2 className="para uppercase-text insights-anim-title ">
                                  {cards.title}
                                </h2>
                                {/* <a href={`${domain}/insight/${cards.slug}`}>
                                <div className="para uppercase-text  card-quarter-title">
                                  Click here to read more
                                </div>
                              </a> */}
                              </div>
                            </div>
                            <div className="para insights-para restrict-three-line ">
                              {cards.shortDescription}
                            </div>
                            <a href={`${domain}/insight/${cards.slug}`}>
                              <div className="read-more semi-para">
                                <div
                                  className={`text-container sem-para uppercase-text color-${
                                    currentLanguage ? currentLanguage : "en"
                                  }`}
                                >
                                  Read More
                                </div>
                                <div className="image-container">
                                  <img
                                    src={
                                      currentLanguage === "en"
                                        ? RMArrowBlackEn
                                        : currentLanguage === "in"
                                        ? RMArrowBlackIn
                                        : currentLanguage === "ja"
                                        ? RMArrowBlackJa
                                        : RMArrowBlack
                                    }
                                    alt="Read More Image. Black Arrow showing the navigation"
                                  />
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                  </Carousel>
                  {/* <CarouselProvider
                  naturalSlideWidth={1000}
                  naturalSlideHeight={1000}
                  totalSlides={insights.length}
                  visibleSlides={3}
                >
                  {insights.length > 3 ? (
                    <div>
                      <ButtonBack className="insights-slider-btn">
                        {" "}
                        <img
                          src={
                            currentLanguage === "en"
                              ? leftArren
                              : currentLanguage === "in"
                              ? leftArrin
                              : currentLanguage === "ja"
                              ? leftArrja
                              : leftArren
                          }
                          alt="Slide"
                        ></img>
                      </ButtonBack>
                      <ButtonNext className="insights-slider-btn">
                        <img
                          src={
                            currentLanguage === "en"
                              ? rightArren
                              : currentLanguage === "in"
                              ? rightArrin
                              : currentLanguage === "ja"
                              ? rightArrja
                              : rightArren
                          }
                          alt="Slide"
                        ></img>
                      </ButtonNext>
                    </div>
                  ) : (
                    <></>
                  )}

                  <Slider>
                    {insights.length > 0 && (
                      <div className="four-insights-block">
                        {insights.map((cards, index) => {
                          return (
                            <Slide index={index}>
                              <div className="four-cards insight-border">
                                <div
                                  className="card"
                                  style={{
                                    backgroundImage: ` url(${cards.image.url}) `,
                                  }}
                                >
                                  <div className="content">
                                    <h2 className="para uppercase-text para insights-anim-title ">
                                      {cards.title}{" "}
                                    </h2>
                                    <a href={`${domain}/insight/${cards.slug}`}>
                                      <div className="para uppercase-text para card-quarter-title">
                                        Click here to read more
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                <div className="para insights-para restrict-three-line ">
                                  {cards.shortDescription}
                                </div>
                                <a href={`${domain}/insight/${cards.slug}`}>
                                  <div className="read-more para">
                                    <div>Read More</div>
                                  </div>
                                </a>
                              </div>
                            </Slide>
                          );
                        })} */}
                  {/* <div className="two-of-four insight-border">
                      <div
                        className="card"
                        style={{
                          backgroundImage: `linear-gradient(
                         to top, rgba(106, 173, 65, 0.05), rgba(0,0,0,0.7)
                        ), url(${insights[1].image.url}) `,
                        }}
                      >
                        <div className="content">
                          <h2 className="semi-title">{insights[1].title}</h2>
                        </div>
                      </div>
                      <div className="semi-para insights-para">
                        {insights[1].shortDescription}
                      </div>
                      <a href={`${domain}/insight/${insights[1].slug}`}>
                      <div className="read-more">
                          <div>Read More</div>
                        </div>
                      </a>
                    </div> */}
                  {/* <div className="three-of-four insight-border small-insight">
                      <div
                        className="card"
                        style={{
                          backgroundImage: `linear-gradient(
                            to top, rgba(106, 173, 65, 0.05), rgba(0,0,0,0.7)
                           ), url(${insights[2].image.url}) `,
                        }}
                      >
                        <div className="content">
                          <h2 className="semi-title">{insights[2].title}</h2>
                        </div>
                      </div>
                      <div className="semi-para insights-para">
                        {insights[2].shortDescription}
                      </div>
                      <a href={`${domain}/insight/${insights[2].slug}`}>
                      <div className="read-more para">
                          <div>Read More</div>
                        </div>
                      </a>
                    </div> */}
                  {/* <div className="four-of-four insight-border small-insight">
                      <div
                        className="card"
                        style={{
                          backgroundImage: `linear-gradient(
                          to top, rgba(106, 173, 65, 0.05), rgba(0,0,0,0.7)
                          ), url(${insights[3].image.url}) `,
                        }}
                      >
                        <div className="content">
                          <h2 className="semi-title">{insights[3].title}</h2>
                        </div>
                      </div>
                      <div className="semi-para insights-para">
                        {insights[3].shortDescription}
                        {console.log(insights[3], "insights 3")}
                      </div>
                      <a href={`${domain}/insight/${insights[3].slug}`}>
                      <div className="read-more para">
                          <div>Read More</div>
                        </div>
                      </a>
                    </div> */}
                  {/* </div>
                    )}
                  </Slider>
                </CarouselProvider>
              </div>

              <div className="insights-mobile-wrapper">
                <CarouselProvider
                  naturalSlideWidth={8}
                  naturalSlideHeight={10}
                  totalSlides={insights.length}
                  visibleSlides={1}
                >
                  <ButtonBack className="insights-slider-btn">
                    {" "}
                    <img
                      src={
                        currentLanguage === "en"
                          ? leftArren
                          : currentLanguage === "in"
                          ? leftArrin
                          : currentLanguage === "ja"
                          ? leftArrja
                          : leftArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonBack>
                  <ButtonNext className="insights-slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? rightArren
                          : currentLanguage === "in"
                          ? rightArrin
                          : currentLanguage === "ja"
                          ? rightArrja
                          : rightArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonNext>

                  <Slider>
                    {insights.length > 0 && (
                      <div className="four-insights-block">
                        {insights.map((cards, index) => {
                          return (
                            <Slide index={index}>
                              <div className="four-cards insight-border">
                                <div
                                  className="card"
                                  style={{
                                    backgroundImage: ` url(${cards.image.url}) `,
                                  }}
                                >
                                  <div className="content">
                                    <h2 className="semi-title insights-anim-title para">
                                      {cards.title}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="para insights-para restrict-three-line ">
                                {cards.shortDescription}
                              </div>
                              <a href={`${domain}/insight/${cards.slug}`}>
                                <div className="read-more para">
                                  <div>Read More</div>
                                </div>
                              </a>
                            </Slide>
                          );
                        })}
                      </div>
                    )}
                  </Slider>
                </CarouselProvider>
              </div>

              <div className="insights-tablet-wrapper">
                <CarouselProvider
                  naturalSlideWidth={8}
                  naturalSlideHeight={10}
                  totalSlides={insights.length}
                  visibleSlides={2}
                >
                  <ButtonBack className="insights-slider-btn">
                    {" "}
                    <img
                      src={
                        currentLanguage === "en"
                          ? leftArren
                          : currentLanguage === "in"
                          ? leftArrin
                          : currentLanguage === "ja"
                          ? leftArrja
                          : leftArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonBack>
                  <ButtonNext className="insights-slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? rightArren
                          : currentLanguage === "in"
                          ? rightArrin
                          : currentLanguage === "ja"
                          ? rightArrja
                          : rightArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonNext>

                  <Slider>
                    {insights.length > 0 && (
                      <div className="four-insights-block">
                        {insights.map((cards, index) => {
                          return (
                            <Slide index={index}>
                              <div className="four-cards insight-border">
                                <div
                                  className="card"
                                  style={{
                                    backgroundImage: `url(${cards.image.url}) `,
                                  }}
                                >
                                  <div className="content">
                                    <h2 className="semi-title insights-anim-title para">
                                      {cards.title}
                                    </h2>
                                  </div>
                                </div>
                                <div className="insights-para">
                                  <div className="para insights-texts restrict-three-line ">
                                    {cards.shortDescription}
                                  </div>
                                </div>

                                <a href={`${domain}/insight/${cards.slug}`}>
                                  <div className="read-more para">
                                    <div>Read More</div>
                                  </div>
                                </a>
                              </div>
                            </Slide>
                          );
                        })}
                      </div>
                    )}
                  </Slider>
                </CarouselProvider>
              </div> */}

                  {showBanner && (
                    <div
                      className="insights-banner-wrapper"
                      onClick={bannerUrl ? window.open(bannerUrl) : "/404"}
                    >
                      <div className="image-wrapper">
                        {" "}
                        <img
                          src={
                            data.gcms.homePageInsights[0].bannerImage
                              ? data.gcms.homePageInsights[0].bannerImage.url
                              : `https://images.unsplash.com/photo-1533134486753-c833f0ed4866?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&cover=format&fit=crop&w=1500&q=80`
                          }
                          alt="Banner Image"
                        ></img>
                      </div>

                      <div className="banner">
                        <div className="heading">
                          {data.gcms.homePageInsights[0].insightsBannerHeading}
                        </div>
                        <div className="semi-para insights-para">
                          {
                            data.gcms.homePageInsights[0]
                              .insightsBannerParagraph
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } catch (error) {
    console.log(error);
  }
};

export default Insights;
