import React, { useEffect, useRef, useState } from "react";

import Fade from "react-reveal/Fade";
import config from "react-reveal/globals";
import useOnScreen from "../../../components/atoms/appearance/Appearanc";
import WipeBtn from "../../../components/atoms/buttons/WipeBtn";
import WipeBtnBlack from "../../../components/atoms/buttons/WipeBtnBlack";
import { useLanguage } from "../../../languageContext";

// import { useLanguage } from "../../languageContext";
// import useOnScreen from "../../components/atoms/appearance/Appearanc";
// import { LightSpeed, Zoom } from "react-reveal";
const IndustryFullImageHeader = ({
  header,
  para,
  btnText,
  btnLink,
  topLeftValue,
  bgImgMobile,
  bgImgTablet,
  isWhite = false,
}) => {
  // const [topValue, setTopValue] = useState(0);
  const currentLanguage = useLanguage();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [first, setFirst] = useState(false);
  const [topMargin, setTopMargin] = useState("75px");
  useEffect(() => {
    // let navBar = document.querySelector(".navbar").offsetHeight;
    // setTopValue(navBar - 5);
    let headerDiv = document.querySelector("#industry-header-image-container");
    headerDiv.classList.add("scale-image");
    return () => {
      try {
      } catch (error) {}
    };
  });

  useEffect(() => {
    try {
      if (!first) {
        let navBar = document.querySelector(".navbar").offsetHeight;
        setTopMargin(navBar - 2);
        // setTotalTopMargin(navBar + menuBar.offsetHeight);
        setFirst(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    config({ ssrFadeout: isVisible });
  }, [isVisible]);

  return (
    <div
      ref={ref}
      id={"industry-header-with-image"}
      className={` section new-industry-header-wrapper `}
      style={{
        height: `calc(100vh - ${topMargin}px)`,
        backgroundColor: `${isWhite ? "transparent" : "black"}`,
      }}
    >
      <div
        id="industry-header-image-container"
        className="background-image-wrapper"
        style={{ backgroundImage: `url(${bgImgTablet})` }}
      >
        {/* <img src={bgImgTablet} alt="" /> */}
      </div>
      <Fade duration={1000}>
        <div
          className="industry-header-info-wrapper"
          style={{
            color: `${isWhite ? "black" : "white"}`,
            background: `${isWhite ? "transparent" : " rgba(0,0,0,0.5);"}`,
          }}
        >
          <div className="content-wrapper">
            <h1 className="heading page-heading">{header}</h1>

            <p className="para text-center">{para}</p>

            <div className="header-cta-wrapper">
              {isWhite ? (
                <WipeBtnBlack
                  text={"Talk to An Expert"}
                  url="/contactus/"
                  className={"wipe-btn-auto-width"}
                />
              ) : (
                <WipeBtn
                  //   className={"green-border green-btn bg-green-btn "}
                  text={"Talk to An Expert"}
                  className={"wipe-btn-auto-width"}
                  url="/contactus/"
                  // link="/contactus/"
                />
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default IndustryFullImageHeader;
