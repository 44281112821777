import React from "react";
import Fade from "react-reveal/Fade";
import LogoTicker from "../LogoTicker";

// Clients logo
import BytonLogo from "../../components/atoms/icons/bytonLogo";
import TfgIcon from "../../components/atoms/icons/TfgLogo";
import ContienentaLogo from "../../components/atoms/icons/ContienentaLogo";
import KasaiLogo from "../../components/atoms/icons/KasaiLogo";
import SumitomoLogo from "../../components/atoms/icons/SumitomoLogo";
import SinteredLogo from "../../components/atoms/icons/SinteredLogo";
import NeatonLogo from "../../components/atoms/icons/NeatonLogo";
import TsTechLogo from "../../components/atoms/icons/TsTechLogo";
import NBHXLogo from "../../components/atoms/icons/NBHXLogo";
import LPLogo from "../../components/atoms/icons/L&PLogo";
import EGLogo from "../../components/atoms/icons/EGLogo";
import UnipressLogo from "../../components/atoms/icons/UnipressLogo";
import BasfLogo from "../../components/atoms/icons/basfLogo";

// Training logo
import HandsOnTrainingIcon from "../../images/servicesassets/training/icons/hands-on-training.png";
import RemoteTrainingIcon from "../../images/servicesassets/training/icons/remote-training.png";
import RealWorldApplicationIcon from "../../images/servicesassets/training/icons/real-world-applications.png";
import DetailAssessmentIcon from "../../images/servicesassets/training/icons/detailed-assessment.png";

const IndustryOurClients = ({
  isAerospace,

  isAdvancedTechnology,
  isAutomotive,
  isManufacturing,
  isLifeAtGoken,
  isKeyFeatures,
}) => {
  return (
    <>
      {isAerospace && (
        <div className="our-clients-section m-top-20px ">
          <h2 className="heading heading-bottom-space mobile-text-center">
            A Few of Our Aerospace Clients​
          </h2>

          <div className="image-wrapper common-bottom-space mobile-text-center">
            <div className="flex-logo">
              <TfgIcon></TfgIcon>
              <LPLogo></LPLogo>
            </div>
          </div>
        </div>
      )}
      {isManufacturing && (
        <div className="our-clients-section m-top-20px ">
          <h2 className="heading heading-bottom-space mobile-text-center">
            A Few of Our Manufacturing Clients​
          </h2>

          <div className="image-wrapper common-bottom-space mobile-text-center">
            <div className="flex-logo">
              <TsTechLogo />
              <EGLogo />
              <SumitomoLogo />
              <SinteredLogo />
            </div>
          </div>
        </div>
      )}
      {isAdvancedTechnology && (
        <div className="our-clients-section m-top-20px">
          <h2 className="heading heading-bottom-space title mobile-text-center">
            OUR CLIENTS
          </h2>

          <div className="industry-clients">
            <LogoTicker>
              <div className="image-wrapper common-bottom-space">
                <div className="flex-logo">
                  <BytonLogo></BytonLogo>
                  <SumitomoLogo></SumitomoLogo>
                  <TfgIcon></TfgIcon>
                </div>
              </div>
            </LogoTicker>
          </div>
        </div>
      )}
      {isAutomotive && (
        <div className="our-clients-section m-top-20px">
          {" "}
          <h2 className="heading heading-bottom-space mobile-text-center">
            A Few of Our Automotive INDUSTRY Clients
          </h2>
          <LogoTicker>
            <div className="image-wrapper common-bottom-space">
              <div className="flex-logo">
                <BytonLogo></BytonLogo>
                <ContienentaLogo></ContienentaLogo>
                <EGLogo></EGLogo>
                <KasaiLogo></KasaiLogo>
                <NeatonLogo></NeatonLogo>
                <SumitomoLogo></SumitomoLogo>
                <TsTechLogo></TsTechLogo>
              </div>
            </div>
          </LogoTicker>
        </div>
      )}
      {/* {isManufacturing && (
        <div className="our-clients-section m-top-20px">
          <Fade bottom>
            <h2 className="heading heading-bottom-space mobile-text-center">
              A Few of our Manufacturing INDUSTRY Clients
            </h2>
          </Fade>
          <LogoTicker>
            <div className="image-wrapper common-bottom-space">
              <div className="flex-logo">
                <KasaiLogo></KasaiLogo>
                <NeatonLogo></NeatonLogo>
                <NBHXLogo></NBHXLogo>
                <SumitomoLogo></SumitomoLogo>
                <UnipressLogo></UnipressLogo>
              </div>
            </div>
          </LogoTicker>
        </div>
      )} */}
      {isLifeAtGoken && (
        <div className="our-clients-section m-top-20px">
          <h2 className="heading mobile-text-center">OUR CLIENTS</h2>
          <div className="industry-clients">
            <LogoTicker>
              <div className="image-wrapper common-bottom-space">
                <div className="flex-logo">
                  <BasfLogo></BasfLogo>
                  <TfgIcon></TfgIcon>
                  <ContienentaLogo></ContienentaLogo>
                  <KasaiLogo></KasaiLogo>
                  <SumitomoLogo></SumitomoLogo>
                  <NeatonLogo></NeatonLogo>
                  <TsTechLogo></TsTechLogo>
                  <NBHXLogo></NBHXLogo>
                  <LPLogo></LPLogo>
                  <EGLogo></EGLogo>
                  <UnipressLogo></UnipressLogo>
                </div>
              </div>
            </LogoTicker>
          </div>
        </div>
      )}
    </>
  );
};

export default IndustryOurClients;
